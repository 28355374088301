:root {
  --theme-color: #293965;
  --white-color: #fff;
  --font-color: #18191F;
  --secondry-color: #1179A2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,h2,h3,h4,h5,h6 {
  font-family: 'Inter', sans-serif;
}
p,a,span {
  font-family: 'Poppins', sans-serif;
}
.px-6 {
    padding-right: 5.5rem;
    padding-left: 5.5rem;
}
.inner-banner {
  background-image: url(./assets/images/inner-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 120px;
  padding-bottom: 46px;
}
.inner-banner-text {
  margin-bottom: 44px;
}
.inner-banner-text h1{
  color: #fff;
  font-weight: 700;
  font-size: 48px;
}
.inner-banner-text p {
  color: #fff;
  font-size: 20px;
}
.star-rating p{
  color: #fff;
  font-size: 16px;
}
.star-rating span {
  color: #4D5765;
}
.f-basis-50 {
  flex-basis: 50%;
}
.courses-section {
  padding: 80px 0px;
}
.nav-signin {
  background-color: #1179a2!important;
}
.pricing-card-content ul {
  padding-left: 0px;
}
.nav-link {
  cursor: pointer;
}
.telegram-section {
  background-image: url('./assets/images/qr-bg.png');
}
.telegram-section {
  padding: 60px 0px;
}
.qr-content {
  padding-left: 64px;
}
.qr-content h1 {
  font-size: 48px;
  margin-bottom: 40px;
}
.qr-content li {
  font-size: 20px;
  margin-bottom: 24px;
}
.telegram-row {
  border-radius: 14px;
}
.dropdown-item {
  cursor: pointer;
}
.main-video-wrapper {
  padding: 18px;
  border: 2px solid #ECF1F9;
  border-radius: 6px;
}
.video-single-detail {
  border-right: 1px solid #4D5765;
  margin-right: 12px;
  padding-right: 12px;
}
.instructor span {
  color: #0D5EF4;
}
.video-rating-count span {
  color: #757C8E;
  font-weight: bold;
}
.thumb-detail h6 {
  font-size: 18px;
}
.playlist-wrap {
  border: 2px solid #ECF1F9;
  padding: 18px;
  border-radius: 6px;
  height: 600px;
  overflow: auto;
}
.single-playlist-wrap {
  cursor: pointer;
}
.video-single-detail-last {
  border: none;
}
.trade-banner h1 {
  color: #293965;
  font-size: 48px;
}
.summary-wrapper {
  padding: 15px 0px;
  border-radius: 8px;
}
.summary-section {
  margin-bottom: 65px;
}
.new-message p {
  color: #293965;
}
.summary-count {
  font-size: 40px;
  font-weight: bold;
  color: #293965;
  border-bottom: 1px solid #EBE3FF;
  padding-bottom: 5px;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 14px;
}
.new-message {
  padding: 0px 18px;
}
.progress-div{
  padding: 0px 18px;
}
section.chart-section {
  margin-bottom: 66px;
  margin-top: 70px;
}
.chart-row {
    border: 1px solid #ECF1F9;
    border-radius: 7px;
}
.course-info-section .nav-tabs {
  border: 0px;
}
.course-info-section .nav-link.active {
  border: 0px;
  border-top: 2px solid #293965 !important;
  border-radius: 0;
  background-color: #F3F7FB;
  border-right: 2px solid #fff;
}
.course-info-section .nav-link {
  text-align: center;
  background-color: #F3F7FB;
  transition: none;
  border-right: 2px solid #fff;
}
.course-info-section .nav-link:hover {
  border: 0px;
  outline: none;
  border-right: 2px solid #fff !important;
}
.course-info-section .nav-link:focus-visible {
  outline: none;
  border: 0px;
}
.summary-tab-content {
  padding: 54px 60px;
  border: 2px solid #f3f7fb;
  border-top: 0;
}
section.course-info-section {
  margin-bottom: 86px;
}
.signup-text span{
  cursor: pointer;
}
.calculator-div input, .calculator-div select {
  width: 100%;
  border: 0px;
  background: #EEEEEE;
  padding: 8px 12px;
  border-radius: 5px;
}

.calculator-div input:focus, .calculator-div select:focus {
  outline: none;
  box-shadow: none;
}
.calculator-div {
  padding: 24px 42px;
  border-radius: 6px;
  margin-top: 24px;
}
.calculator-heading h2 {
  color: #1179A2;
  font-size: 32px;
}
section.risk-calculator-section {
  padding-top: 56px;
  padding-bottom: 120px;
}
.calculator-btn {
  background: #293965;
  color: #fff;
  width: 100%;
  border: 0;
  padding: 11px 0px;
  border-radius: 6px;
}
.calculator-result p {
  background: #1179A2;
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
}
/* #modal-root .modal-header {
  background: #272727;
  color: #fff;
}
#modal-root .modal-body, #modal-root .modal-footer {
  background: #272727;
}
#modal-root .modal-header button {
  background: unset;
  color: #fff;
  font-size: 18px;
} */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.customModal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.header {
  background: #293965;
  padding: 1rem;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 1rem;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}
.modal-card {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}
.modal-open {
  overflow: hidden;
}
.white-section .container {
  border: 1px solid #293965;
  border-radius: 5px;
}
.profile-form-group input {
  width: 90%;
  background: #EEEEEE;
  border: 0;
  padding: 10px 14px;
  border-radius: 7px;
  margin-top: 8px;
  font-size: 16px;
}
.profile-form-group input:focus {
  outline: none;
}
.lms-btn {
  border: 0;
  color: #fff;
  background: #293965;
  font-size: 18px;
  padding: 8px 49px;
  border-radius: 4px;
}
.payment-field input {
  width: 100%;
}
.news-wrap {
  margin-bottom: 58px;
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 58px;
}
.news-img img {
  border: 1px solid #a9a9a9;
}
p.news-tag {
  display: inline-block;
  background: #777474;
  color: #fff;
  padding: 4px 5px;
  font-size: 12px;
}
p.news-hour {
  font-size: 14px;
  color: gray;
}
.about-para {
  font-size: 20px;
}
.privacy-table-content {
  text-decoration: none;
  list-style: none;
  font-size: 20px;
}
.many-courses-content ul {
  font-size: 20px;
}
.del-price {
  font-size: 26px !important;
}
.inner-ul li{
  list-style: disc !important;
}
.inner-ul {
  padding-left: 24px !important;
}
.courses-card .card-img {
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}
.preview-title {
  height: 50px;
}
.spinner-wrap {
  position: fixed;
  z-index: 9;
  height: 100vh;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: #00000030;
}
.spinner-border {
  position: absolute;
  left: 49%;
  top: 50%;
  color: #00b7e1;
  width: 40px;
  height: 40px;
}
.membershipPrice .pricing-bg {
  background-color: #fff!important;
}
.membershipPrice h2, .membershipPrice h4 {
  color: rgba(0,0,0,.87)!important;
}
.thumbnail img {
  width: 165px;
}
.news-img img {
  width: 270px;
}
.follow-footer {
  color: #1179a2;
}
.footer-info .footer-logo {
  width: 285px;
}
/* 
@media (min-width: 768px) {
  .customModal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
} */