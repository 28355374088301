.counter{
    background-image: url(../../assets/images/counter-bg.png);
    background-size: cover;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
}

.counter-content h2{
    font-size: 40px;
    font-weight: bold;
    color: var(--white-color);
}

.counter-content p{
    color: var(--white-color);
    font-size: 14px;
}

.counter-content hr{
    border-top: 1px solid var(--white-color);
    opacity: 1;
}