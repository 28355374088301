.sign-img{
    background-color: var(--theme-color);
    height: 100vh;
}
.sign-img img{
    width: 480px;
}

.signup-text{
    font-size: 14px;
    color: #C2C2C2;
}

.signup-text span{
    font-size: 14px;
    color: var(--theme-color);
}

@media (min-width: 300px) and (max-width: 600px) {
    .sign-img {
        height: auto;
        padding: 20px;
    }
  }