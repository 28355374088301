.login-img{
    background-color: var(--theme-color);
    height: 100vh;
}
.login-img img{
    width: 460px;
}
.login-form img{
    width: 352px;
}
.login-form h2{
    font-size: 18px;
    color: var(--theme-color);
    text-align: center;
}

.login-form .form-control{
    background-color: #EEEEEE;
    border-radius: 7px;
    border: none;
    font-size: 14px;
    height: 42px;
    position: relative;
}

.login-form .form-label{
    font-size: 14px;
    color: #555555;
}

.login-form a{
    color: var(--theme-color);
}

.login-form .form-control:focus{
    box-shadow: none;
}

.login-form .form-login-btn button{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: var(--theme-color);
    border-radius: 8px;
    color: var(--white-color);
    width: 100%;
    border: none;
    padding: 10px;
}

.login-form .form-signup-btn button{
    background-color: transparent;
    border-radius: 8px;
    color: var(--theme-color);
    width: 100%;
    border: 1px solid var(--theme-color);
    font-weight: bold;
    padding: 10px;
}

.login-form .input-icon{
    background-color: var(--theme-color);
    border-radius: 5px;
    height: 41px;
    width: 45px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 32px;
    right: 0;
}
.login-form .input-icon img{
    width: 18px;

}

.separator {
    width: 50%;
    border-bottom: 1px solid #C2C2C2;
    position: relative;
    margin: 40px 0px;
}

.separator::before {
    content: "OR";
    position: absolute;
    left: 45%;
    top: -11px;
    margin: 0 auto;
    background-color: #fff;
    padding: 0px 10px;
    color: #C2C2C2;
}

@media (min-width: 300px) and (max-width: 600px) {
    .separator {
        width: 100%;
    }
    .login-img{
        height: auto;
        padding: 20px;
    }
  }