.footer{
    background-color: var(--theme-color);
}

.footer-info p{
    color: var(--white-color);
    font-size: 14px;
}

.footer-links h2{
    color: var(--secondry-color);
    font-size: 18px;
}

.footer-links p{
    color: var(--white-color);
    font-size: 14px;
}

.footer-form .form-control{
    background-color: transparent;
    border: 1px solid var(--secondry-color);
    color: var(--white-color);
}

.footer-form h2{
    color: var(--secondry-color);
    font-size: 18px;
}

.footer-form button{
    background-color: var(--secondry-color);
    border: none;
    color: var(--white-color);
    padding: 9px 20px;
    border-radius: 7px;
    font-size: 15px;
}