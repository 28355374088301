.cta-content h2{
    font-size: 40px;
    color: var(--theme-color);
    font-weight: bold;
}

.cta-content button{
    background-color: var(--theme-color);
    border: none;
    border-radius: 7px;
    padding: 8px 30px;
    color: var(--white-color);
}