
.navbar {
    background: var(--white-color);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.nav-signup-btn button {
    background-color: var(--theme-color);
    color: var(--white-color);
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
}

.nav-link {
    color: var(--theme-color);
}

.site-logo {
    width: 195px;
}

.navbar-expand-lg .navbar-nav {
    gap: 30px;
}