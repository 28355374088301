.bg-banner{
    overflow: hidden;
    padding-top: 89px;
    text-align: center;
    background-image: url(../../assets/images/banner-image.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    z-index: 1;
}

.banner-content-img img{
    width: 568px;
}

.banner-content h2{
    font-size: 48px;
    font-weight: bold;
    color: var(--white-color);
    text-align: left;
    width: 65%;
}
.banner-content p{
    font-size: 20px;
    color: var(--white-color);
    text-align: left;
    width: 65%;
}

.banner-content button{
    background-color: var(--secondry-color);
    color: var(--white-color);
    border-radius: 40px;
    border: none;
    padding: 7px 30px;
}