.courses-card {
    background: #FFFFFF;
    border-radius: 24px;
    border: 1px solid #D0D7E3;
    cursor: pointer;
}

.popular-courses {
    background-color: var(--theme-color);
}

.course-profile {
    background-color: var(--secondry-color);
    padding: 10px;
    border-radius: 113px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
}

.popular-courses-heading h2{
    font-size: 35px;
    font-weight: bold;
    color: var(--white-color);
}

.course-profile p {
    color: var(--white-color) !important;
    font-size: 15px !important;
}

.course-profile-content h4 {
    font-size: 16px;
    color: var(--font-color);
}

.courses-card h2 {
    font-size: 18px;
    color: var(--font-color);
}

.courses-card p {
    font-size: 20px;
    color: var(--secondry-color);
}

.courses-card span {
    font-size: 20px;
    color: #4D5765;
}

.courses-card h3 {
    font-size: 16px;
    color: #4D5765;
}

.review-text p{
    font-size: 14px;
    color: var(--font-color);
}
.lesson-btns {
    border-top: 1px solid #D0D7E3;
    margin-top: 28px;
}
.lesson-border {
    border-right: 1px solid #D0D7E3;
}