.many-courses-img img {
    width: 400px;
}

.many-courses-content h2{
    font-size: 38px;
    font-weight: 400;
    color: var(--font-color);
}

.many-courses-content p{
    color: #4D5765;
    font-size: 14px;
}

.many-courses-content h4{
    font-size: 18px;
    font-weight: 500;
    color: var(--font-color);
    line-height: 30px;
}

.many-courses-content button{
    background-color: var(--theme-color);
    padding: 12px 34px;
    border-radius: 24px;
    color: var(--white-color);
    border: none;
}