.pricing-bg {
    background-color: var(--theme-color);
}

.pricing-card {
    background: var(--white-color);
    border: 1px solid #DBDBDB;
    box-shadow: 0px 4px 5px 1px rgba(193, 193, 193, 0.35);
    border-radius: 4px;
    padding: 31px 20px;
    height: 813px;
    position: relative;
}

.pricing-card-content span{
    font-size: 18px;
    color: var(--theme-color);
    font-weight: bold;
}
ul.price-height {
    height: 516px;
    overflow: auto;
}
.pricing-card-content h1{
    font-size: 45px;
    color: var(--theme-color);
    font-weight: 800;
}
.pricing-card-content p{
    font-size: 14px;
    color: var(--theme-color);
    color: #4D5765;
}

.pricing-card-content button{
    background-color: #1179a2;
    border: 2px solid #1179a2;
    padding: 6px 28px;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translateX(-50%);
}

.pricing-card-content ul li{
    text-decoration: none;
    list-style: none;
    font-size: 16px;
    padding: 0;
    text-align: left;
}

.pricing-card-content hr{
    width: 40%;
    margin: 21px auto;
}

.pricing-card-heading h2{
    font-size: 48px;
    font-weight: bold;
    color: var(--white-color);
}
.pricing-card-heading p{
    font-size: 14px;
    color: var(--white-color);
}

.nav-pills .nav-link{
    background: #fff;
    border-radius: 0 !IMPORTANT;
}
.nav-pills .nav-link.active{
    background-color: var(--secondry-color);
}
