.benefit-working-heading h2{
    font-size: 48px;
    font-weight: bold;
    color: var(--font-color);
}

.benefit-working-logo{
    padding: 17px;
    border-radius: 113px;
    background-color: var(--theme-color);
    display: flex;
    justify-content: center;
    align-items: center;
}
.benefit-working-logo img {
    height: 48px;
    width: 48px;
}

.benefit-working-content h3{
    font-size: 22px;
    font-weight: 500;
    color: var(--font-color);
}
.benefit-working-content p{
    font-size: 15px;
    color: var(--font-color);
}